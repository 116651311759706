export const environment = {
  production: false,
  mode: "Dev",
  treebuLedger: "https://dev.treebucapital.com",

  // Servers
  treebuHasura: "https://dev.treebucapital.com",
  treebuCapital: "https://dev.treebucapital.com",
  treebuCrypto: "https://dev.treebucapital.com",
  prefixTreebuHasura: "api/rest",
  prefixTreeCrypto: "api/ms-crypto/v1",
  prefixTreeLedger: "api/treebu-ledger/v1",
  prefixBasic: "api",

  // Media Files
  multimedia: "/assets",

  //Recaptcha
  siteKey: "6LeDSMYpAAAAAOZZT1Wv2gZ6B0UOMkPq1S-rbV-6",
  secretKey: "6LeDSMYpAAAAAJwVksnmhu0_M91fHOL8Osbu4j7Q",

  //Metamap
  flowId: "65a6805a952402001c0934ed",

  // Firebase
  firebaseConfig: {
    apiKey: "AIzaSyCZq52CDcYp4tFd85kKomqj35KROmuYAME",
    authDomain: "treebu-dev-521b7.firebaseapp.com",
    projectId: "treebu-dev-521b7",
    storageBucket: "treebu-dev-521b7.appspot.com",
    messagingSenderId: "181466184352",
    appId: "1:181466184352:web:4b1680a9aaac266985e7c5",
  },

  timerRefreshToken: 3300,
};
